module.exports = function qualifiedFieldName(field, aggregation, movingFn) {
  function withAggregation(name) {
    if (aggregation === 'latest') {
      return name;
    }

    return `${name}:${aggregation}`;
  }

  function withMovingFn(name) {
    if (!movingFn) {
      return name;
    }

    return `${name}:${movingFn.fn}:${movingFn.window}`;
  }

  return withMovingFn(withAggregation(field));
};
