import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSuspenseQuery } from '@tanstack/react-query';

import ButtonBar from '@stordata/cmdb-common/src/components/ButtonBar/index.jsx';

import ScopeList from '../ScopeList/index.jsx';
import LogoBar from '../LogoBar/index.jsx';
import logo from '../OauthLoginForm/s.png';
import useReturnTo from '../../services/useReturnTo/index.js';

import styles from '../OauthLoginForm/OauthLoginForm.module.scss';

export default function OauthConsentForm() {
  const { t: __ } = useTranslation(),
        [interactionUrl] = useReturnTo(),
        { data: { client, context } } = useSuspenseQuery({ queryKey: [`${interactionUrl}/context`] });

  return (
    <Card className={styles.oauthLoginForm}>
      <form method="POST" action={`${interactionUrl}/consent`}>
        <CardContent>
          <Box textAlign="center">
            <Typography className={styles.info}>{__('%(client)s would like your consent', { client: client?.displayName })}</Typography>
          </Box>
          <LogoBar imageOne={client.image} imageTwo={logo} altOne={`${client.displayName} Logo`} altTwo="Stordata Logo" />
          <Box textAlign="center">
            <Typography variant="body2">{__('You are about to allow %(client)s to', { client: client?.displayName })}</Typography>
          </Box>
          <ScopeList scopes={context?.params.scope} />
        </CardContent>
        <CardActions>
          <ButtonBar>
            <Button className="deny_button" type="submit" name="action" value="deny">{__('Deny')}</Button>
            <Button className="allow_button" type="submit" name="action" value="allow">{__('Allow')}</Button>
          </ButtonBar>
        </CardActions>
      </form>
    </Card>
  );
}
