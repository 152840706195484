'use strict';

const METRICS = require('./metrics.cjs');

/**
 * Returns the human-readable label of a metric.
 *
 * @param metric {string} The metric
 *
 * @returns {string} The description of the metric
 */
module.exports = function aggregationDisplayNameOf(metric) {
  return METRICS[metric][1];
};
