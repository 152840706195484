import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loading({ size, color, className }) {
  return (
    <Box textAlign={'center'} className={className} width={size} height={size}>
      <CircularProgress size={size} color={color} />
    </Box>
  );
}
