import { useEffect } from 'react';
import _ from 'lodash';

/**
 * A drop-in replacement for `React.useEffect` that checks for actual changes to the dependencies before applying the
 * effect function. Useful for instance to avoid the first, initial effect at component mount.
 *
 * @param effect {function} The effect to apply
 * @param deps {any[]} The dependencies, as per `useEffect`
 * @param reference {any[]} The reference array, against which the dependencies are compared
 */
export default function useEffectOnChange(effect, deps, reference) {
  function applyIfChanged() {
    if (!_.isEqual(deps, reference)) {
      return effect();
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(applyIfChanged, deps);
}
