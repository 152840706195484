module.exports = function linkToItemTemplate({ techno, type, uuid, field, name }) {
  function fragmentToUrl(url, fragment) {
    if (!fragment) {
      return url;
    }

    return `${url}/${encodeURIComponent(fragment)}`;
  }

  return [techno, type, uuid, field, name].reduce(fragmentToUrl, '/dashboards');
};
