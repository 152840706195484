'use strict';

const CONTROLS = require('./controls.cjs');

module.exports = function thresholdSupportsMultipleValues(threshold) {
  switch (threshold.control) {
    case CONTROLS.IS:
    case CONTROLS.IS_NOT:
      return true;
    default:
      return false;
  }
};
