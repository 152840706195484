const Serializable = require('../Serializable.cjs');

module.exports = class ElasticQueryCriterion extends Serializable {

  constructor(value) {
    super();

    this.value = value;
  }

  isValid() {
    if (this.value instanceof ElasticQueryCriterion) {
      return this.value.isValid();
    }

    return this.value !== undefined && this.value !== null && this.value !== '';
  }

  toString() {
    return String(this.value);
  }

};
