import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useQueryParam } from 'use-query-params';

import navigateTo from '@stordata/cmdb-common/src/services/navigateTo/index.js';
import useRouteTo from '@stordata/cmdb-common/src/services/useRouteTo/index.js';
import backend from '@stordata/cmdb-common/src/services/backend/index.js';
import ButtonBar from '@stordata/cmdb-common/src/components/ButtonBar/index.jsx';
import ClientSideLink from '@stordata/cmdb-common/src/components/ClientSideLink/index.jsx';

import useReturnTo from '../../services/useReturnTo/index.js';
import useJWT from '../../services/useJWT/index.js';

export default function LoginForm() {
  const [jwt] = useJWT(),
        [signup] = useQueryParam('signup'),
        [returnTo] = useReturnTo(),
        routeTo = useRouteTo(),
        { t: __ } = useTranslation(),
        { register, handleSubmit, formState: { isSubmitting } } = useForm(),
        [auth, setAuth] = useState({});

  function login(data) {
    return backend.post('/auth/login', data)
      .then(() => navigateTo(returnTo))
      .catch((err) => {
        const { data: { error, code, message, jwt: token } } = err.response;

        // Technical error ?
        if (error) {
          return setAuth({ error });
        }

        // Handle backend error codes, so that transitions occur in the app
        switch (code) {
          case 'OTP_REQUIRED':
            return routeTo('/otp');
          case 'USER_MUST_CHANGE_PASSWORD':
            return routeTo('/reset/confirm', { force: true, jwt: token });
          default:
            return setAuth({ message });
        }
      });
  }

  function showAlertMessage() {
    if (auth.error) {
      return (<Alert severity="error">{__('There was an error, please try again')}</Alert>);
    }

    if (auth.message) {
      return (<Alert severity="error">{__(auth.message)}</Alert>);
    }

    if (signup === 'confirmed') {
      return (<Alert severity="success">{__('Registration successfull, you can now log in')}</Alert>);
    }
  }

  return (
    <form onSubmit={handleSubmit(login)}>
      {showAlertMessage()}
      <CardContent>
        <TextField inputProps={register('username')} label={__('Username')} fullWidth required />
        <TextField inputProps={register('password')} type="password" label={__('Password')} fullWidth required />
        <input {...register('jwt')} type="hidden" value={jwt} />
      </CardContent>
      <CardActions>
        <ButtonBar>
          <ClientSideLink to="/reset" >{__('Forgot your password ?')}</ClientSideLink>
          <Button data-testid="submit" type="submit" disabled={isSubmitting}>{__('Sign in')}</Button>
        </ButtonBar>
      </CardActions>
    </form>
  );
}
