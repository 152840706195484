import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { defaultQueryFn } from '../../services/queryClient/index.jsx';
import Loading from '../Loading/index.jsx';
import MarkdownRenderer from '../MarkdownRenderer/index.jsx';

export default function LocalizedMarked({ folder, options }) {
  function queryKeyFor(lang) {
    return [`/locales/${folder}/${lang}.md`];
  }

  async function tryAllLocales() {
    for (const candidateLanguage of languages) {
      const res = await defaultQueryFn({ queryKey: queryKeyFor(candidateLanguage) }).catch(() => {});

      if (res) {
        return res;
      }
    }

    return null; // We _need_ to return something, undefined is no valid query data
  }

  const { i18n: { language, languages }, t: __ } = useTranslation(),
        { data, isFetching } = useQuery({ queryKey: queryKeyFor(language), queryFn: tryAllLocales });

  if (isFetching) {
    return <Loading size={20} />;
  }

  return (
    <MarkdownRenderer>
      {__(data, options)}
    </MarkdownRenderer>
  );
}
