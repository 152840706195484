import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { useSnackbar } from '@stordata/material-ui-snackbar-provider';
import backend from '@stordata/cmdb-common/src/services/backend/index.js';
import ButtonBar from '@stordata/cmdb-common/src/components/ButtonBar/index.jsx';

import Captcha from '../Captcha/index.jsx';

export default function SignupForm() {
  const { t: __ } = useTranslation(),
        { register, handleSubmit, formState: { errors, isSubmitting }, setError } = useForm(),
        snackbar = useSnackbar();

  function signup(data) {
    return backend.post('/signup', data)
      .then(() => snackbar.showMessage(__('Thanks for subscribing. We\'ve sent you an email with the next steps')))
      .catch((err) => {
        const parameter = err.response?.data.error?.err?.parameter;

        if (parameter) {
          setError(parameter, {
            type: 'manual',
            message: __('This field is invalid')
          });
        }
      });
  }

  return (
    <form onSubmit={handleSubmit(signup)}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField inputProps={register('email')} error={!!errors.email} helperText={errors.email?.message} type="email" label={__('Email')} fullWidth required />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Captcha />
          </Grid>
          <Grid item xs={12} sm>
            <TextField inputProps={register('captcha')} error={!!errors.captcha} helperText={errors.captcha?.message} label={__('Security code')} fullWidth required />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <ButtonBar>
          <Button data-testid="submit" type="submit" disabled={isSubmitting}>{__('Sign up')}</Button>
        </ButtonBar>
      </CardActions>
    </form>
  );
}
