import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import { PROFILES } from '@stordata/cmdb-shared';

import backend from '../backend/index.js';

export default function useMe() {
  const { data: me } = useSuspenseQuery({ queryKey: ['/api/users/me'] }),
        queryClient = useQueryClient(),
        { mutateAsync: changeUserPassword } = useMutation({ mutationFn: changeUserPasswordMutationFn }),
        { mutateAsync: revokeUser2FA } = useMutation({ mutationFn: revokeUser2FAMutationFn }),
        { mutateAsync: enableUser2FA } = useMutation({ mutationFn: enableUser2FAMutationFn }),
        { mutateAsync: revokeOauthConsent } = useMutation({ mutationFn: revokeOauthConsentMutationFn, onSuccess: refetchOAuthConsents }),
        { mutateAsync: toggleCustomer2FA } = useMutation({ mutationFn: toggleCustomer2FARequiredMutationFn, onSuccess: refetchMe });

  function changeUserPasswordMutationFn(variables) {
    return backend.put('/api/users/me/password', variables);
  }

  function revokeUser2FAMutationFn() {
    return backend.get('/api/users/me/otp/revoke');
  }

  function enableUser2FAMutationFn(variables) {
    return backend.post('/api/users/me/otp/activate', variables);
  }

  function revokeOauthConsentMutationFn(id) {
    return backend.delete(`/api/users/me/oauth/consents/${id}`);
  }

  function toggleCustomer2FARequiredMutationFn() {
    return backend.put('/api/users/me/customer/2FA');
  }

  function refetchOAuthConsents() {
    return queryClient.refetchQueries({ queryKey: ['/api/users/me/oauth/consents'] });
  }

  function refetchMe() {
    return queryClient.refetchQueries({ queryKey: ['/api/users/me'], exact: true });
  }

  return {
    me,
    isSuperAdmin: me.profile === PROFILES.SUPERADMIN,
    isStordataAdmin: me.profile === PROFILES.STORDATAADMIN || me.profile === PROFILES.SUPERADMIN,
    isImpersonating: !!me.sudoer,
    isPartOfACustomer: !!me.customer,
    isCustomerAdmin: !!me.isCustomerAdmin,
    changeUserPassword,
    revokeUser2FA,
    enableUser2FA,
    revokeOauthConsent,
    toggleCustomer2FA
  };
}
