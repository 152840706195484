import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

export default function Contacts() {
  return (
    <Box textAlign="center">
      28 Rue Saint-Honoré - 78000 Versailles<br/>
      11 Chemin des Anciennes Vignes - 69410 Champagne-au-Mont-d’Or<br/>
      6 Rue Maurice Caunes - 31200 Toulouse<br/>
      34 Quai Magellan - 44000 Nantes<br/>
      9 Rue Icare - 67960 Entzheim<br/>
      <Link href="tel:0130214242">Téléphone : 01 30 21 42 42</Link><br/>
      <Link href="mailto:contact@stordata.fr">Email : contact@stordata.fr</Link>
    </Box>
  );
}
