export default function addTenantToHeaders(acc) {
  const location = new URL(window.location.href),
        tenant = location.searchParams.get('tenant');

  if (tenant) {
    acc.headers['X-CMDB-Tenant'] = tenant;
  }

  return acc;
}
