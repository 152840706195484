const ElasticQueryCriterion = require('./ElasticQueryCriterion.cjs'),
      ElasticQueryFieldCriterion = require('./ElasticQueryFieldCriterion.cjs'),
      ElasticQueryMatchCriterion = require('./ElasticQueryMatchCriterion.cjs'),
      ElasticQueryRangeCriterion = require('./ElasticQueryRangeCriterion.cjs');

/**
 * Models an Elastic query string query containing criteria that are joined using an operator (AND, OR, NOT)
 * This provides a high level API to add criteria to the query, supporting various matchers (match, term, etc.)
 *
 * @see https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html
 */
module.exports = class ElasticQueryString extends ElasticQueryCriterion {

  /**
   * Builds a new ElasticQueryString
   *
   * @param [operator=AND] {string} The operator for this query string
   */
  constructor(operator = 'AND') {
    super([]);

    this.operator = operator;
    this.negateNextCriterion = false;
  }

  /**
   * Adds a "match" criterion to this query.
   * This supports multiple values (if you pass an array as `value`) and in this case matches ANY term provided, similar
   * to what the Elasticsearch "terms" query does.
   *
   * @param field {string} The field to match on
   * @param value {string|number|boolean|Array} The value to match
   *
   * @returns {ElasticQueryString} Returns the query, so that chaining is supported
   */
  match(field, value) {
    const isMultiple = Array.isArray(value),
          mkSingleCriterion = v => new ElasticQueryMatchCriterion(v),
          mkMultipleCriterion = v => v.reduce((q, term) => q.criterion(mkSingleCriterion(term)), new ElasticQueryString('OR')),
          mkCriterion = isMultiple ? mkMultipleCriterion : mkSingleCriterion;

    return this.criterion(new ElasticQueryFieldCriterion(field, mkCriterion(value)));
  }

  /**
   * Adds a "range" criterion to this query.
   * A range will match values between two bounds, inclusively for the lower bound, exclusively for the higher bound.
   *
   * @param field {string} The field to match on
   * @param min {string|number|Date} The lower, inclusive bound
   * @param [max] {string|number|Date} The higher, exclusive bound
   *
   * @returns {ElasticQueryString} Returns the query, so that chaining is supported
   */
  range(field, min, max) {
    const subQuery = new ElasticQueryString('OR')
      .criterion(new ElasticQueryRangeCriterion(min, '>=')) // Inclusive
      .criterion(new ElasticQueryRangeCriterion(max, '<')); // Exclusive

    return this.criterion(new ElasticQueryFieldCriterion(field, subQuery));
  }

  /**
   * Adds a "greater than" criterion to this query.
   *
   * @param field {string} The field to match on
   * @param value {string|number|Date} The value to match
   *
   * @returns {ElasticQueryString} Returns the query, so that chaining is supported
   */
  gt(field, value) {
    return this.criterion(new ElasticQueryFieldCriterion(field, new ElasticQueryRangeCriterion(value, '>')));
  }

  /**
   * Adds a "greater than or equal" criterion to this query.
   *
   * @param field {string} The field to match on
   * @param value {string|number|Date} The value to match
   *
   * @returns {ElasticQueryString} Returns the query, so that chaining is supported
   */
  gte(field, value) {
    return this.criterion(new ElasticQueryFieldCriterion(field, new ElasticQueryRangeCriterion(value, '>=')));
  }

  /**
   * Adds a "less than" criterion to this query.
   *
   * @param field {string} The field to match on
   * @param value {string|number|Date} The value to match
   *
   * @returns {ElasticQueryString} Returns the query, so that chaining is supported
   */
  lt(field, value) {
    return this.criterion(new ElasticQueryFieldCriterion(field, new ElasticQueryRangeCriterion(value, '<')));
  }

  /**
   * Adds a "greater than" criterion to this query.
   *
   * @param field {string} The field to match on
   * @param value {string|number|Date} The value to match
   *
   * @returns {ElasticQueryString} Returns the query, so that chaining is supported
   */
  lte(field, value) {
    return this.criterion(new ElasticQueryFieldCriterion(field, new ElasticQueryRangeCriterion(value, '<=')));
  }

  /**
   * Adds a "free text" criterion to this query.
   * This performs a full text search on the given pattern.
   *
   * @param value {string} The text to search
   *
   * @returns {ElasticQueryString} Returns the query
   */
  text(value) {
    return this.criterion(new ElasticQueryCriterion(value));
  }

  /**
   * Adds an "exists" criterion to this query.
   * This is implemented as a "match anything" criterion, using the star (*) wildcard character.
   *
   * @param field {string} The field to match on
   *
   * @returns {ElasticQueryString} Returns the query, so that chaining is supported
   */
  exists(field) {
    return this.match(field, '*');
  }

  /**
   * Next added criterion will be negated, i.e.: (NOT criterion)
   */
  not() {
    this.negateNextCriterion = true;

    return this;
  }

  criterion(criterion) {
    if (criterion?.isValid()) {
      if (this.negateNextCriterion) {
        this.negateNextCriterion = false;
        this.value.push(new ElasticQueryString('NOT').criterion(criterion));
      } else {
        this.value.push(criterion);
      }
    }

    return this;
  }

  isValid() {
    return this.value.length > 0;
  }

  toString() {
    const str = this.operator === 'NOT' ? `(NOT ${this.value})` : this.value.join(` ${this.operator} `);

    if (str) {
      // This is a bit dumb and can produce extra parenthesis...
      // But this avoids complex heuristics to decide whether parenthesis are required
      if (this.value.length > 1) {
        return `(${str})`;
      }

      return str;
    }

    return null;
  }

};
