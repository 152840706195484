'use strict';

const CONTROLS = require('./controls.cjs');

const SYMBOL = {
  [CONTROLS.MORE_THAN]: '>',
  [CONTROLS.MORE_THAN_OR_EQUAL]: '>=',
  [CONTROLS.LESS_THAN]: '<',
  [CONTROLS.LESS_THAN_OR_EQUAL]: '<=',
  [CONTROLS.IS]: '=',
  [CONTROLS.IS_NOT]: '!=',
  [CONTROLS.CONTAINS]: '><',
  [CONTROLS.DOES_NOT_CONTAIN]: '<>',
  [CONTROLS.BEFORE]: '<',
  [CONTROLS.BEFORE_OR_EQUAL]: '<=',
  [CONTROLS.AFTER]: '>',
  [CONTROLS.AFTER_OR_EQUAL]: '>=',
  [CONTROLS.EXISTS]: '*',
  [CONTROLS.DOES_NOT_EXIST]: '!*',
  [CONTROLS.CHANGED_FROM_PREVIOUS]: '<<>'
};

module.exports = function thresholdControlSymbol(value) {
  return SYMBOL[value];
};
