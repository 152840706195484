const findByTechnoAndType = require('./findByTechnoAndType.cjs');

const SELF_IDENTIFICATION_FIELDS = [
  'metadata.uuid',
  'metadata.name.keyword',
  'metadata.object',
  'metadata.fullyQualifiedName'
];

module.exports = function findInventoryLink(techno, type, name, model) {
  if (SELF_IDENTIFICATION_FIELDS.includes(name)) {
    return { techno, type, name, uuid: 'metadata.uuid' }; // Fake link to self
  }

  function byNameOrObject(link) {
    return link.name === name || link.object === name;
  }

  return findByTechnoAndType({ techno, type }, model)?.links?.find(byNameOrObject);
};
