import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default function ForgetMeConfirmation() {
  const { t: __ } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">{__('Your account removal has been registered by Stordata Team.')}</Typography>
      </CardContent>
    </Card>
  );
}
