import Box from '@mui/material/Box';

import StordataLogo from '@stordata/cmdb-common/src/components/StordataLogo/index.jsx';

import styles from './Header.module.scss';

export default function Header() {
  return (
    <Box padding={2} className={styles.logo}>
      <StordataLogo large />
    </Box>
  );
}
