import cx from 'classnames';

import styles from './Code.module.scss';

export default function Code({ inline, className, dataTestId, children }) {
  if (inline) {
    return (
      <code className={styles.code}>{children}</code>
    );
  }

  return (
    <pre data-testid={dataTestId} className={cx(styles.pre, className)}>
      {children}
    </pre>
  );
}
