const ElasticQueryCriterion = require('./ElasticQueryCriterion.cjs'),
      encodeElasticsearchMatchValue = require('./encodeElasticsearchMatchValue.cjs');

module.exports = class ElasticQueryMatchCriterion extends ElasticQueryCriterion {

  /**
   * Escapes the value of this criterion according to
   * https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html#_reserved_characters
   */
  toString() {
    return encodeElasticsearchMatchValue(super.toString());
  }

};
