const thresholdHasTarget = require('./thresholdHasTarget.cjs'),
      thresholdHasRawValue = require('./thresholdHasRawValue.cjs'),
      thresholdRequiresValue = require('./thresholdRequiresValue.cjs'),
      thresholdHasPreset = require('./thresholdHasPreset.cjs');

module.exports = function isThresholdValueSet(threshold) {
  if (!threshold) {
    return false;
  }

  if (!thresholdRequiresValue(threshold)) {
    return true;
  }

  return thresholdHasTarget(threshold) || thresholdHasPreset(threshold) || thresholdHasRawValue(threshold);
};
