module.exports = {
  MORE_THAN: 'moreThan',
  MORE_THAN_OR_EQUAL: 'moreThanOrEqualTo',
  LESS_THAN: 'lessThan',
  LESS_THAN_OR_EQUAL: 'lessThanOrEqualTo',
  IS: 'itIs',
  IS_NOT: 'itIsNot',
  CONTAINS: 'itContains',
  DOES_NOT_CONTAIN: 'itDoesNotContain',
  BEFORE: 'before',
  BEFORE_OR_EQUAL: 'beforeOrEqual',
  AFTER: 'after',
  AFTER_OR_EQUAL: 'afterOrEqual',
  EXISTS: 'exists',
  DOES_NOT_EXIST: 'doesNotExist',
  CHANGED_FROM_PREVIOUS: 'changedFromPrevious'
};
