const intervals = require('./intervals.cjs');

const minutesByInterval = intervals.reduce(keyByInterval, {});

module.exports = function intervalInMinutes(interval) {
  return minutesByInterval[interval] || 0;
};

function keyByInterval(acc, interval) { /* eslint-disable prefer-destructuring */
  acc[interval[0]] = interval[1]; // 2000's Javascript

  return acc;
}
