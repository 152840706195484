'use strict';

const OPERATORS = require('../operators.cjs'),
      first = require('../first.cjs'),
      ElasticQueryString = require('./ElasticQueryString.cjs');

const ALL = '*',
      CRITERIA = {
        [OPERATORS.FILTER_OPERATOR_MATCHES]: (query, filter) => query.match(filter.field, filter.value),
        [OPERATORS.FILTER_OPERATOR_DOES_NOT_MATCH]: (query, filter) => query.not().match(filter.field, filter.value),
        [OPERATORS.FILTER_OPERATOR_EXISTS]: (query, filter) => query.exists(filter.field),
        [OPERATORS.FILTER_OPERATOR_DOES_NOT_EXIST]: (query, filter) => query.not().exists(filter.field),
        [OPERATORS.FILTER_OPERATOR_GREATER_THAN]: (query, filter) => query.gt(filter.field, filter.value),
        [OPERATORS.FILTER_OPERATOR_LESS_THAN]: (query, filter) => query.lt(filter.field, filter.value),
        [OPERATORS.FILTER_OPERATOR_GREATER_THAN_OR_EQUAL]: (query, filter) => query.gte(filter.field, filter.value),
        [OPERATORS.FILTER_OPERATOR_LESS_THAN_OR_EQUAL]: (query, filter) => query.lte(filter.field, filter.value),
        [OPERATORS.FILTER_OPERATOR_FREE_TEXT]: (query, filter) => query.text(filter.value)
      };

module.exports = function formatFiltersAsQuery(filters, options) {
  if (!filters) {
    return;
  }

  function reduceFilterToCriterion(query, filter) {
    const filterType = first(filter['metadata.type']),
          filterTechno = first(filter['metadata.techno']);

    if ((filterTechno !== ALL && options.techno !== filterTechno) || (filterType !== ALL && options.type !== filterType)) {
      return query; // Filters should not be applied when querying another object type -> do not append the criterion
    }

    return CRITERIA[filter.operator](query, filter);
  }

  return filters.reduce(reduceFilterToCriterion, new ElasticQueryString());
};
