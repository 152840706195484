/**
 * Marker class for objects that implement a custom toString() function and as such, are serializable
 */
module.exports = class Serializable {

  toString() {
    return null; // This will prevent serialization by default, forcing sub-classes to implement toString()
  }

};
