import backend from '../backend/index.js';
import SSRAwareQueryClient from './SSRAwareQueryClient.js';
import ConfigurableQueryCache from './ConfigurableQueryCache.js';

const queryCache = new ConfigurableQueryCache();

export default new SSRAwareQueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: Infinity,
      queryFn: defaultQueryFn
    },
    mutations: {
      onError: err => queryCache.onError(err) // Use the same error handler for queries AND mutations
    }
  }
});

export function defaultQueryFn({ queryKey: [url, params] }) {
  return backend.get(url, { params }).then(res => res.data);
}

export function defaultQueryFnPost({ queryKey: [url, { params, body }] }) {
  return backend.post(url, body, { params }).then(res => res.data);
}

export function defaultInfiniteQueryFn({ queryKey: [url, params], pageParam }) {
  return defaultQueryFn({ queryKey: [url, { ...params, after: pageParam }] });
}

export function defaultGetNextPageParam(page) {
  return page.hits?.at(-1)?.sort; // Uses Elasticsearch's scroll_after cursor pagination
}
