import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ClientSideLink from '@stordata/cmdb-common/src/components/ClientSideLink/index.jsx';
import useTenantConfiguration from '@stordata/cmdb-common/src/services/useTenantConfiguration/index.js';

import Contacts from '../Contacts/index.jsx';
import LoginForm from '../LoginForm/index.jsx';
import SignupForm from '../SignupForm/index.jsx';

export default function LoginTabs() {
  const { t: __, i18n: { resolvedLanguage } } = useTranslation(),
        { loginApp: { disableSignup, disableContact, welcomeTo } } = useTenantConfiguration();

  return (
    <Card>
      <CardContent>
        <Box textAlign={'center'} py={2}>
          <Typography variant={'h5'}>{welcomeTo[resolvedLanguage]}</Typography>
        </Box>
        <TabContext value={useLocation().pathname}>
          <TabList>
            <Tab label={__('Login')} component={ClientSideLink} data-testid={'login'} to={'/'} value={'/'} />
            {!disableSignup && <Tab label={__('Sign up')} component={ClientSideLink} data-testid={'signup'} to={'/signup'} value={'/signup'}/>}
            {!disableContact && <Tab label={__('Contact')} component={ClientSideLink} data-testid={'contact'} to={'/contact'} value={'/contact'}/>}
          </TabList>
          <TabPanel value={'/'}>
            <LoginForm />
          </TabPanel>
          {!disableSignup && (
            <TabPanel value={'/signup'}>
              <SignupForm />
            </TabPanel>
          )}
          {!disableContact && (
            <TabPanel value={'/contact'}>
              <Contacts />
            </TabPanel>
          )}
        </TabContext>
      </CardContent>
    </Card>
  );
}
