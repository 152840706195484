export default function fieldOf(item, ...fields) {
  if (!item) {
    return;
  }

  for (const field of fields) {
    if (field in item) {
      return item[field];
    }
  }
}
