import { useCallback, useState } from 'react';

export default function useBooleanState(initial = false) {
  const [state, setState] = useState(initial);

  function setTrue() {
    setState(true);
  }

  function setFalse() {
    setState(false);
  }

  function toggle() {
    setState(current => !current);
  }

  return [
    state,
    setState,
    useCallback(setTrue, [setState]),
    useCallback(setFalse, [setState]),
    useCallback(toggle, [setState])
  ];
}
