import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Email from '@mui/icons-material/Email';
import Person from '@mui/icons-material/Person';
import Business from '@mui/icons-material/Business';
import AssignmentInd from '@mui/icons-material/AssignmentInd';
import { useTranslation } from 'react-i18next';

const SCOPES = [
  ['openid', AssignmentInd],
  ['email', Email],
  ['profile', Person],
  ['customer', Business]
]; // Other scopes will be ignored, stordata.custom for instance

export default function ScopeList({ scopes }) {
  const { t: __ } = useTranslation(),
        supportedScopes = SCOPES.filter(([scope]) => scopes.indexOf(scope) > -1);

  return (
    <Box border={1} borderColor={'grey.300'} borderRadius={'5px'} marginY={2}>
      <List disablePadding data-testid={'scopes'}>
        {supportedScopes.map(([scope, Icon], i) => (
          <ListItem key={scope} divider={i < supportedScopes.length - 1}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemSecondaryAction>
              <ListItemText primary={__(`oauth.scope.${scope}`)}/>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
