import { LATEST_METRIC } from '../constants/aggregations.js';

export const METADATA = {
  UUID: 'metadata.uuid',
  NAME: 'metadata.name.keyword',
  RESOURCE_ID: 'metadata.resourceId',
  RESOURCE_TYPE: 'metadata.resourceType',
  FULLY_QUALIFIED_NAME: 'metadata.fullyQualifiedName',
  READ_AT: 'metadata.readAt',
  TECHNO: 'metadata.techno',
  TYPE: 'metadata.type',
  TAGS: 'metadata.tags.name',
  TOKEN: 'metadata.token',
  OBJECT: 'metadata.object'
};

export const ITEM_IDENTIFICATION_FIELDS = [METADATA.UUID, METADATA.NAME, METADATA.FULLY_QUALIFIED_NAME];
export const ITEM_IDENTIFICATION_SERIES = ITEM_IDENTIFICATION_FIELDS.map(field => ({ field, metric: LATEST_METRIC }));

export const INVENTORY_ITEM_MATCHED_QUERY = 'search.item';
export const FIELDS_MATCHED_QUERY = 'fields';
export const OPERATORS_MATCHED_QUERY = 'operators';
export const DASHBOARD_MATCHED_QUERY = 'search.dashboard';
