import axios from 'axios';
import qs from 'query-string';

import { mapObjectValues, Serializable } from '@stordata/cmdb-shared';

import scopesSerializer from './serializers/scopes.js';
import dropOnUploadProgressInTests from './interceptors/dropOnUploadProgressInTests.js';
import addTenantToHeaders from './interceptors/addTenantToHeaders.js';

/**
 * We should find a better way to handle these.
 * After all, serializing to the query string in the browser should be the same as serializing to the backend
 */
const SPECIAL_PARAMS = {
  scope: scopesSerializer
};

export default createBackendInstance();

export function paramsSerializer(params) {
  return qs.stringify(mapObjectValues(params, normalizeParamValue), { skipNull: true });
}

//

function normalizeParamValue(v, k) {
  if (v instanceof Date) {
    return v.toISOString();
  }

  if (v instanceof Serializable) {
    return v.toString();
  }

  if (k in SPECIAL_PARAMS) {
    return SPECIAL_PARAMS[k](v);
  }

  return v;
}

function createBackendInstance() {
  const instance = axios.create({
    headers: {
      'X-CMDB-Source': 'ReactApp',
      'X-CMDB-TimeZone': new Intl.DateTimeFormat().resolvedOptions().timeZone // Works everywhere except IE 11
    },
    paramsSerializer
  });

  instance.interceptors.request.use(addTenantToHeaders);

  /* istanbul ignore else */
  if (import.meta.env.MODE === 'test') {
    instance.interceptors.request.use(dropOnUploadProgressInTests);
  }

  return instance;
}
