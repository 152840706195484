import { createContext } from 'react';

export default createContext({
  theme: null,
  topBelowAppBar: 0,
  windowSize: {
    width: 0,
    height: 0
  }
});
