import { createTheme, ThemeProvider } from '@mui/material/styles';
import { enUS, frFR } from '@mui/material/locale';
import { enUS as gridEnUS, frFR as gridFrFR } from '@mui/x-data-grid-premium/locales';
import { enUS as pickersEnUS, frFR as pickersFrFR } from '@mui/x-date-pickers-pro/locales';
import { useMemo } from 'react';
import { useWindowScroll, useWindowSize } from 'react-use';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import ApplicationContext from './ApplicationContext.js';
import { LANGUAGE_EN, LANGUAGE_FR, useInitializedI18n } from '../../services/i18n/index.js';
import useTenantConfiguration from '../../services/useTenantConfiguration/index.js';

export function defaultTheme(mode, { primary, secondary }) {
  return {
    palette: {
      mode,
      primary,
      secondary
    },
    typography: {
      body1: {
        fontSize: '0.875rem'
      }
    },
    components: {
      MuiTextField: {
        defaultProps: {
          margin: 'dense'
        }
      },
      MuiTooltip: {
        defaultProps: {
          disableInteractive: true,
          PopperProps: {
            container: getFullscreenElement
          }
        }
      },
      MuiPopover: {
        defaultProps: {
          container: getFullscreenElement
        }
      },
      MuiModal: {
        defaultProps: {
          container: getFullscreenElement,
          disableEnforceFocus: true
        }
      },
      MuiPopper: {
        defaultProps: {
          container: getFullscreenElement
        }
      },
      MuiAccordion: {
        defaultProps: {
          TransitionProps: {
            unmountOnExit: true,
            mountOnEnter: true
          }
        }
      },
      MuiTabs: {
        defaultProps: {
          variant: 'scrollable'
        }
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover'
        }
      }
    }
  };
}

const locales = {
  [LANGUAGE_FR]: [frFR, gridFrFR, pickersFrFR],
  [LANGUAGE_EN]: [enUS, gridEnUS, pickersEnUS]
};

export const APP_BAR_HEIGHT_DENSE = 48; // px. We have the const in a CSS module too -_-

export default function ApplicationContextProvider({ children, colorMode = 'light', language }) {
  const { colorScheme } = useTenantConfiguration(),
        { resolvedLanguage } = useInitializedI18n(language),
        theme = useMemo(mkTheme, [resolvedLanguage, colorMode, colorScheme]),
        windowSize = useWindowSize(),
        windowScroll = useWindowScroll(),
        topBelowAppBar = useMemo(computeTopBelowAppBar, [windowScroll.y, windowSize.height]),
        value = useMemo(mkProviderValue, [theme, topBelowAppBar, windowSize]);

  function mkTheme() {
    return createTheme(defaultTheme(colorMode, colorScheme), ...locales[resolvedLanguage]);
  }

  function computeTopBelowAppBar() {
    if (windowScroll.y > windowSize.height) {
      return 0; // If user scrolls down more than one page the AppBar disappears, so we can safely sit at the top
    }

    /**
     * When user scrolls down, the AppBar progressively disappears at the top, until it's fully hidden.
     * We try to be smart by computing the delta between scroll position and window size in order to get the (almost)
     * same effect with our "top" value: making it follow the position of the AppBar.
     * If the AppBar is fully visible (i.e.: user did not scroll enough yet), we sit right under it
     */
    return Math.min(APP_BAR_HEIGHT_DENSE, windowSize.height - windowScroll.y);
  }

  function mkProviderValue() {
    return {
      theme,
      topBelowAppBar,
      windowSize
    };
  }

  return (
    <ApplicationContext.Provider value={value}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ApplicationContext.Provider>
  );
}

function getFullscreenElement() {
  return document.fullscreenElement;
}
