const dataSeriesHasMetric = require('./dataSeriesHasMetric.cjs'),
      translateField = require('./translateField.cjs'),
      aggregationOf = require('./aggregationOf.cjs');

/**
 * Translates a data series.
 * This function translates "Count of Object" as the correct form of the actual object type. Otherwise, it translates
 * "<Metric> <Field>" as you would expect. If the series has no metric, only the field name is translated.
 *
 * @param techno {string} The series' techno
 * @param type {string} The series' type
 * @param series {{field: string, metric?: string}} The data series
 * @param i18n {{t: function(key: string, options?: { count?: number, defaultValue: string })}} The actual translation function
 * @param options {{count?: number, defaultValue: string}} Options directly given to translateField
 * @param [options.count=2] {number} The actual count, when known. Used to return the correct singular or plural form. This defaults
 *  to 2 in order to return the plural form for "Count of Object" that gets used more often in titles, column headers, etc.
 * @param options.defaultValue {string} A default value used when the field cannot be translated. This value is mandatory as custom
 *  metrics are not translated in i18n files but from an external source, the value must be passed-in manually to this function
 *
 * @returns {string} The translated field name
 *
 * @see translateField
 */
module.exports = function translateDataSeries(techno, type, series, i18n, options) {
  if (dataSeriesHasMetric(series)) {
    if (aggregationOf(series.metric) === 'cardinality' && series.field === 'metadata.object') {
      return translateField(techno, type, series.field, i18n, { ...options, count: options?.count ?? 2 });
    }

    return `${i18n.t(series.metric)} ${translateField(techno, type, series.field, i18n, options)}`;
  }

  return translateField(techno, type, series.field, i18n, options);
};
