import useTenantConfiguration from '../../services/useTenantConfiguration/index.js';

export default function StordataLogo({ large, isDarkMode }) {
  const { tenantLogo, tenantLogoDark, tenantSmallLogo } = useTenantConfiguration();

  function getSource() {
    if (!large) {
      return tenantSmallLogo; // Fine in dark mode too
    }

    return isDarkMode ? tenantLogoDark : tenantLogo;
  }

  return <img src={getSource()} alt={'Stordata Logo'} data-large={large} />;
}
