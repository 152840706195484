import { useLocation, Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { forwardRef } from 'react';

function ClientSideLink({ to, hash = '', children, disabled, ...rest }, ref) {
  const { search } = useLocation();

  if (disabled) {
    return children;
  }

  return (
    <Link ref={ref} component={RouterLink} to={to + search + hash} {...rest}>
      {children}
    </Link>
  );
}

export default forwardRef(ClientSideLink);
