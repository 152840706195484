module.exports = {
  PLAN: {
    PREMIUM: 'premium'
  },
  STATUS: {
    LICENSED: 'licensed',
    EXPIRING: 'expiring',
    EXPIRED: 'expired',
    UNLICENSED: 'unlicensed'
  }
};
