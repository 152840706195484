'use strict';

/**
 * Builds and return a new empty "business hours" model.
 * The model consists of a two-dimensions array where the first dimension is the day of the week, starting at 1 (the 0
 * slot is actually empty) and the second dimension is the hour on this specific day, starting at 0 and ending at 23.
 * Each slot will be either 0 (non-business hour) or 1 (business hour). We use numbers to save bytes over the wire.
 *
 * @param [callback] {function(day: number, hour: number): boolean} A callback function responsible to tell if the hour
 *   is currently a business hour (true) or not (false).
 *
 * @returns {number[][]} The business hours model
 */
module.exports = function newBusinessHoursModel(callback = nonBusiness) {
  const model = [[]];

  for (let day = 1; day <= 7; day++) {
    model.push([]);

    for (let hour = 0; hour <= 23; hour++) {
      model[day].push(+callback(day, hour)); // The "+" casts to number
    }
  }

  return model;
};

//

function nonBusiness() {
  return false;
}
