import HotkeyKeySequence from './HotkeyKeySequence/index.jsx';

export default class Hotkey {

  /**
   * Creates a new Hotkey.
   * Typically, we'll not use this constructor directly but rather expose singletons as static members of this class.
   *
   * @param hint {React.ReactNode} The hint that is displayed to the user when this hotkey is rendered. Can be anything
   *   from a single letter to a more complex JSX element. Use at your own risk.
   * @param matchers {object} An object used to match typed key sequences to this hotkey. This object will be walked and
   *   all configured matchers will be compared. To match, a hotkey must pass all matchers.
   * @param [matchers.ctrlKey=false] {boolean} Whether the "Control" key must be pressed
   * @param [matchers.altKey=false] {boolean} Whether the "Alt" key must be pressed
   * @param [matchers.shiftKey=false] {boolean} Whether the "Shift" key must be pressed
   * @param [matchers.key] {string} The key to match, when matching on a specific key
   * @param [matchers.code] {string} The key code to match, when matching on a specific key code
   *
   * @see CTRL_ALT_F
   * @see KeyboardEvent
   */
  constructor(hint, matchers) {
    this.hint = hint;
    this.matchers = matchers;
  }

  /**
   * Checks whether this hotkey matches the given KeyboardEvent.
   * Works by comparing the key code and all provided matchers against the typed key sequence.
   *
   * @param e {KeyboardEvent} The event to match this hotkey against
   */
  matches(e) {
    return Object.entries(this.matchers).every(([matcher, value]) => e[matcher] === value);
  }

  /**
   * Renders the user hint for this hotkey (i.e.: the keyboard sequence to type)
   *
   * @returns {JSX.Element} The rendered key sequence
   */
  renderKeySequence() {
    return (
      <HotkeyKeySequence hotkey={this} />
    );
  }

  static SEARCH_EVERYTHING = ['hotkey.search.everything', 'Search everything'];

  static DISPLAY_HOTKEYS = ['hotkey.display.hotkeys', 'Display hotkeys'];

  static CREATE_DASHBOARD = ['hotkey.create.dashboard', 'New dashboard'];

  static GO_TO_HOME = ['hotkey.goto.home', 'Go to welcome page'];

  static GO_TO_MY_ACCOUNT = ['hotkey.goto.my.account', 'Go to my account'];

  static GO_TO_MY_REPORTS = ['hotkey.goto.my.report', 'Go to my reports'];

  static GO_TO_DOCUMENTATION = ['hotkey.goto.documentation', 'Go to documentation'];

  static GO_TO_DEFAULT_DASHBOARD = ['hotkey.goto.default.dashboard', 'Go to default dashboard'];

  static DASHBOARD_DELETE = ['hotkey.dashboard.delete', 'Delete dashboard'];

  static DASHBOARD_TOGGLE_EDIT = ['hotkey.dashboard.toggle.edit', 'Enable/Disable dashboard edit mode'];

  static DASHBOARD_RENAME = ['hotkey.dashboard.rename', 'Rename dashboard'];

  static DASHBOARD_DUPLICATE = ['hotkey.dashboard.duplicate', 'Duplicate dashboard'];

  static DASHBOARD_PDF_PREVIEW = ['hotkey.dashboard.pdf.preview', 'Export as PDF'];

  static DASHBOARD_XLS_EXPORT = ['hotkey.dashboard.xls.export', 'Export as XLS'];

  static DASHBOARD_TOGGLE_EVENTS = ['hotkey.dashboard.toggle.events', 'Enable/Disable events'];

  static DASHBOARD_TOGGLE_AUTOMATIC_EVENTS = ['hotkey.dashboard.toggle.events.automatic', 'Enable/Disable automatic events'];

  static DASHBOARD_SUPERVISION_MODE = ['hotkey.dashboard.supervision', 'Enable/Disable supervision mode'];

  static FAB(label) { return ['hotkey.fab', label]; }

  static TOGGLE_MENU = ['hotkey.toggle.menu', 'Collapse/Expand main menu'];

  static TOGGLE_COLOR_MODE = ['hotkey.toggle.color.mode', 'Dark mode/Light mode'];

  static JUMP_PREVIOUS_SECTION = ['hotkey.dashboard.section.previous', 'Jump to previous dashboard section'];

  static JUMP_NEXT_SECTION = ['hotkey.dashboard.section.next', 'Jump to next dashboard section'];

  static TOGGLE_LANGUAGE = ['hotkey.toggle.language', 'Change your language'];

  static CTRL_ALT_F = new Hotkey('F', { ctrlKey: true, altKey: true, code: 'KeyF' });

  static SLASH = new Hotkey('/', { key: '/' });

  static CTRL_K = new Hotkey('K', { ctrlKey: true, code: 'KeyK' });

  static CTRL_ALT_C = new Hotkey('C', { ctrlKey: true, altKey: true, code: 'KeyC' });

  static QUESTION_MARK = new Hotkey('?', { key: '?' });

  static CTRL_ALT_H = new Hotkey('H', { ctrlKey: true, altKey: true, code: 'KeyH' });

  static CTRL_ALT_I = new Hotkey('I', { ctrlKey: true, altKey: true, code: 'KeyI' });

  static CTRL_ALT_R = new Hotkey('R', { ctrlKey: true, altKey: true, code: 'KeyR' });

  static CTRL_ALT_D = new Hotkey('D', { ctrlKey: true, altKey: true, code: 'KeyD' });

  static CTRL_ALT_S = new Hotkey('S', { ctrlKey: true, altKey: true, code: 'KeyS' });

  static DEL = new Hotkey('Del', { code: 'Delete' });

  static E = new Hotkey('e', { ctrlKey: false, altKey: false, shiftKey: false, key: 'e' });

  static R = new Hotkey('r', { ctrlKey: false, altKey: false, shiftKey: false, key: 'r' });

  static D = new Hotkey('d', { ctrlKey: false, altKey: false, shiftKey: false, key: 'd' });

  static P = new Hotkey('p', { ctrlKey: false, altKey: false, shiftKey: false, key: 'p' });

  static X = new Hotkey('x', { ctrlKey: false, altKey: false, shiftKey: false, key: 'x' });

  static CTRL_E = new Hotkey('E', { ctrlKey: true, shiftKey: false, code: 'KeyE' });

  static CTRL_SHIFT_E = new Hotkey('E', { ctrlKey: true, shiftKey: true, code: 'KeyE' });

  static FELEVEN = new Hotkey('F11', { ctrlKey: false, shiftKey: false, code: 'F11' });

  static PLUS = new Hotkey('+', { ctrlKey: false, altKey: false, key: '+' });

  static CTRL_LEFT = new Hotkey('Left', { ctrlKey: true, code: 'ArrowLeft' });

  static CTRL_L = new Hotkey('L', { ctrlKey: true, code: 'KeyL' });

  static CTRL_UP = new Hotkey('Up', { ctrlKey: true, code: 'ArrowUp' });

  static CTRL_DOWN = new Hotkey('Down', { ctrlKey: true, code: 'ArrowDown' });

  static CTRL_I = new Hotkey('I', { ctrlKey: true, shiftKey: false, altKey: false, code: 'KeyI' });

}
