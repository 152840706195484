'use strict';

const METRICS = require('./metrics.cjs');

/**
 * Returns the Elasticsearch aggregation corresponding to the given metric.
 *
 * @param metric {string} The metric
 *
 * @returns {string} The Elasticsearch aggregation
 */
module.exports = function aggregationOf(metric) {
  if (!metric) {
    return 'latest';
  }

  return METRICS[metric][0];
};
