const thresholdHasTarget = require('./thresholdHasTarget.cjs'),
      thresholdControlSymbol = require('./thresholdControlSymbol.cjs'),
      thresholdHasPreset = require('./thresholdHasPreset.cjs'),
      CONTROLS = require('./controls.cjs');

/**
 * Formats a trigger expression.
 *
 * @param trigger {object} The trigger
 * @param threshold {object} The threshold to output the expression for
 *
 * @returns {string} The formatted expression
 */
module.exports = function formatTriggerExpression(trigger, threshold) {
  let { value } = threshold;

  if (thresholdHasTarget(threshold)) {
    value = threshold.target.name;
  } else if (thresholdHasPreset(threshold)) {
    value = threshold.preset.name;
  }

  if (!trigger) {
    return `${thresholdControlSymbol(threshold.control)} ${value}`;
  }

  // For this specific control, both previous and current values are contained in the trigger value
  if (threshold.control === CONTROLS.CHANGED_FROM_PREVIOUS) {
    const [current, previous] = trigger.trigger.value;

    return `${current} ${thresholdControlSymbol(threshold.control)} ${previous}`;
  }

  return `${trigger.trigger.value} ${thresholdControlSymbol(threshold.control)} ${value}`;
};
