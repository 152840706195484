module.exports = {
  FILTER_OPERATOR_MATCHES: ':',
  FILTER_OPERATOR_DOES_NOT_MATCH: '!:',
  FILTER_OPERATOR_EXISTS: ':*',
  FILTER_OPERATOR_DOES_NOT_EXIST: '!:*',
  FILTER_OPERATOR_GREATER_THAN: '>',
  FILTER_OPERATOR_GREATER_THAN_OR_EQUAL: '>=',
  FILTER_OPERATOR_LESS_THAN: '<',
  FILTER_OPERATOR_LESS_THAN_OR_EQUAL: '<=',
  FILTER_OPERATOR_FREE_TEXT: '~'
};
