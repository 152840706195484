import { useTranslation } from 'react-i18next';

export default function PasswordCriteria() {
  const { t: __ } = useTranslation();

  return (
    <>
      {__('Your password should contain at least')}{':'} <br />
      {' - '}{__('%(d)s characters', { d: 8 })} <br />
      {' - '}{__('%(d)s number', { d: 1 })} <br />
      {' - '}{__('%(d)s uppercase', { d: 1 })} <br />
      {' - '}{__('%(d)s special character', { d: 1 })}
    </>
  );
}
