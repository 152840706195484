import ReactMarkdown from 'react-markdown';
import Tooltip from '@mui/material/Tooltip';
import remarkGfm from 'remark-gfm';
import cx from 'classnames';

import Code from '../Code/index.jsx';
import AutoRelativeOrAbsoluteLink from '../AutoRelativeOrAbsoluteLink/index.jsx';

import styles from './MarkdownRenderer.module.scss';

export default function MarkdownRenderer({ height, className, children }) {
  const components = {
    code: InlineCode,
    a: Link,
    img: Image,
    pre: Code
  };

  function Image({ src, alt, title }) {
    return (
      <Tooltip title={title}>
        <img src={src} alt={alt} className={styles.image} height={height} />
      </Tooltip>
    );
  }

  return (
    <ReactMarkdown className={cx(styles.MarkdownRenderer, className)} components={components} remarkPlugins={[remarkGfm]}>
      {children}
    </ReactMarkdown>
  );
}

// Custom components

function InlineCode(props) {
  return (
    <Code inline {...props} />
  );
}

function Link({ href, title, children }) {
  return (
    <Tooltip title={title}>
      <AutoRelativeOrAbsoluteLink to={href}>
        {children}
      </AutoRelativeOrAbsoluteLink>
    </Tooltip>
  );
}
