const _ = require('lodash');

/**
 * A minimalistic template engine.
 * Replaces all occurrences of `%object.path%` in the given _string_ from the corresponding
 * value in the given _model_. Any depth of object path can be specified.
 * This function leaves alone tokens that it couldn't replace, and does not support default values whatsoever.
 * Replaces all occurrences of `%object.nested.fn()` in the given _string_ by the result of calling the specified
 * method on the given _model_. Any depth of function path can be specified.
 *
 * @param string {String} The template string
 * @param model {Object} The template model
 *
 * @returns {String} The given string, with rendered template tokens
 */
module.exports = function renderSimpleTemplate(string, model) {
  function replacer(match, key) {
    if (key.endsWith('()')) {
      return _.invoke(model, key.substring(0, key.length - 2), match);
    }

    return _.get(model, key, match);
  }

  return string.replace(/%(.+?)%/g, replacer);
};
