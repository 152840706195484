const ElasticQueryCriterion = require('./ElasticQueryCriterion.cjs');

module.exports = class ElasticQueryFieldCriterion extends ElasticQueryCriterion {

  constructor(field, criterion) {
    super(criterion);

    this.field = field;
  }

  toString() {
    return `${this.field}:${super.toString()}`;
  }

};
