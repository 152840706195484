import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import logo from './s.png';
import LoginForm from '../LoginForm/index.jsx';
import LogoBar from '../LogoBar/index.jsx';
import useReturnTo from '../../services/useReturnTo/index.js';

import styles from './OauthLoginForm.module.scss';

export default function OauthLoginForm() {
  const [interactionUrl] = useReturnTo(),
        { data } = useSuspenseQuery({ queryKey: [`${interactionUrl}/context`] }),
        { t: __ } = useTranslation(),
        { image, displayName } = data.client;

  return (
    <Card className={styles.oauthLoginForm}>
      <CardContent>
        <Box textAlign="center">
          <Typography className={styles.info}>{__('Log in to access %(client)s', { client: displayName })}</Typography>
        </Box>
        <LogoBar imageOne={image} imageTwo={logo} altOne={`${displayName} Logo`} altTwo="Stordata Logo" />
      </CardContent>
      <LoginForm />
    </Card>
  );
}
