/**
 * Transforms an object's values through a callback function.
 * The keys are not changed but the callback has access to it in case it needs it.
 *
 * This file (and any other file in the _shared_ folder) should not use object or array destructuring. If it does,
 * babel adds a polyfill at compile time to support destructuring. This polyfill is imported using an ES module "import"
 * which then makes Webpack thinks the whole file is an ES module, while it's CommonJS (because you know, the backend)
 *
 * See
 *  - https://github.com/facebook/create-react-app/issues/6163
 *  - https://github.com/webpack/webpack/issues/8613
 *  - https://github.com/babel/babel-loader/issues/757
 *
 * @param object {object} The source object
 * @param fn {function(value: any, key: string): any} The callback function. Must return the updated value
 *
 * @returns {object} The transformed object
 */
module.exports = function mapObjectValues(object, fn) {
  if (!object) {
    return object;
  }

  function mapObjectValue(entry) {
    const key = entry[0],
          value = entry[1]; // 2000's Javascript -_-

    return [key, fn(value, key)];
  }

  return Object.fromEntries(Object.entries(object).map(mapObjectValue));
};
