'use strict';

const CONTROLS = require('./controls.cjs');

module.exports = function thresholdRequiresValue(threshold) {
  switch (threshold.control) {
    case CONTROLS.EXISTS:
    case CONTROLS.DOES_NOT_EXIST:
    case CONTROLS.CHANGED_FROM_PREVIOUS:
      return false;
    default:
      return true;
  }
};
