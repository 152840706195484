import scopeSerializer from './scope.js';
import technoOf from '../../helpers/technoOf.js';
import typeOf from '../../helpers/typeOf.js';

export function inflateScope(flatScope) {
  if (!flatScope) {
    return [];
  }

  const multiScope = flatScope.reduce((acc, scopeElement) => {
    const key = `${technoOf(scopeElement)}/${typeOf(scopeElement)}`;

    if (!(key in acc)) {
      acc[key] = [scopeElement];
    } else {
      acc[key].push(scopeElement);
    }

    return acc;
  }, {});

  return Object.values(multiScope);
}

/**
 * Serializes "multi"-scopes.
 * A multi-scope is an array of scopes, used to filter using the intersection (logical AND) of underlying scopes. Each
 * individual scopes are still filtered as a union (logical OR) as you would expect.
 *
 * @param scopes {object[][]} The scopes to serialize
 *
 * @returns {undefined|string[]} The serialized scopes, suitable to be passed as URL query param
 */
export default function scopesSerializer(scopes) {
  return inflateScope(scopes).map(scopeSerializer).filter(Boolean);
}
