import Grid from '@mui/material/Grid';
import CheckCircle from '@mui/icons-material/CheckCircle';

import styles from './LogoBar.module.scss';

export default function LogoBar({ imageOne, imageTwo, altOne, altTwo }) {
  return (
    <div className={styles.logoBar}>
      <Grid container spacing={1} className={styles.images}>
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <Grid item><img src={imageOne} data-testid="imageOne" alt={altOne} className={styles.imageOne} /></Grid>
          <Grid item className={styles.spacer} />
          <Grid item><CheckCircle className={styles.icon} data-testid="icon" /></Grid>
          <Grid item className={styles.spacer} />
          <Grid item><img src={imageTwo} data-testid="imageTwo" alt={altTwo} className={styles.imageTwo} /></Grid>
        </Grid>
      </Grid>
    </div>
  );
}
