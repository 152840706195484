import { QueryClient } from '@tanstack/react-query';

export default class SSRAwareQueryClient extends QueryClient {

  constructor(config) {
    super(config);

    this.setSSRQueriesData();
  }

  /**
   * Initializes queries with SSR-provided initial data.
   * window.ssr get filled by the backend ONLY when the app is used in production mode.
   * We're supposed to be able to make Webpack dev server support this but the documented settings didn't work...
   *
   * @see https://webpack.js.org/configuration/dev-server/#devserverdevmiddleware
   */
  setSSRQueriesData() {
    if (window.ssr) {
      window.ssr.queries.forEach(({ key, data }) => {
        this.setQueryData(key, data);
      });
    }
  }

}
