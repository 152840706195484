import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

import useBooleanState from '../../../services/useBooleanState/index.js';

export default function CommandPalette({ hotkeys, onCommand }) {
  const { t: __ } = useTranslation(),
        [autocompleteOpen,, openAutocomplete, closeAutocomplete] = useBooleanState();

  function openPopupIfNotClickEvent(event) {
    if (event.type !== 'mousedown') {
      openAutocomplete();
    }
  }

  return (
    <Autocomplete
      disableClearable
      blurOnSelect={true}
      clearOnBlur={true} // These two work in combination to avoid filling the input with the selected value
      clearOnEscape={true}
      autoHighlight // Allows to press "Enter" when there is a single option
      open={autocompleteOpen}
      onOpen={openPopupIfNotClickEvent} // To not hide the table that already lists the hotkeys
      onClose={closeAutocomplete}
      size={'small'}
      options={hotkeys}
      value={null} // No value is ever required, we react to onChange
      onChange={(event, [id]) => onCommand(id)}
      getOptionLabel={([, [description]]) => description}
      renderInput={params => (
        <TextField autoFocus placeholder={__('Search in available commands')} {...params} />
      )}
      renderOption={(props, [id, [description, primary]]) => (
        <ListItem {...props} key={id}>
          <ListItemText>{description}</ListItemText>
          <ListItemSecondaryAction>{primary.renderKeySequence()}</ListItemSecondaryAction>
        </ListItem>
      )}
    />
  );
}
