import { useContext, useEffect } from 'react';

import HotkeysContext from '../../components/HotkeysProvider/HotkeysContext.jsx';

export default function useHotkey(hotkey, handler, description) {
  const { register } = useContext(HotkeysContext);

  function registerHotkey() {
    if (!hotkey || !handler) {
      return;
    }

    return register(hotkey, handler, description);
  }

  useEffect(registerHotkey, [register, hotkey, handler, description]);
}
