import Grid from '@mui/material/Grid';
import { Children, cloneElement } from 'react';

export default function ButtonBar({ children, justifyContent = 'flex-end' }) {
  function cloneAsPrimaryAction(child) {
    return cloneElement(child, { variant: 'contained' });
  }

  function renderUpgradedChildren() {
    const count = Children.count(children);

    return Children.map(children, (child, i) => (
      <Grid item>
        {i === count - 1 ? cloneAsPrimaryAction(child) : child}
      </Grid>
    ));
  }

  return (
    <Grid container spacing={1} p={1} direction={'row'} justifyContent={justifyContent} alignItems={'center'}>
      {renderUpgradedChildren()}
    </Grid>
  );
}
