module.exports = function oidComparator(a, b) {
  const parsedA = a.split('.'),
        parsedB = b.split('.');

  for (let i = 0; i < Math.max(parsedA.length, parsedB.length); i++) {
    const left = +(parsedA[i] || 0),
          right = +(parsedB[i] || 0);

    if (left < right) {
      return -1;
    }

    if (left > right) {
      return 1;
    }
  }

  return 0;
};
