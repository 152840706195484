import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import styles from './InfoBox.module.scss';

export default function InfoBox({ severity = 'info', variant = 'outlined', children }) {
  return (
    <Alert variant={variant} severity={severity} classes={{ message: styles.message }}>
      <Typography variant={'caption'}>
        {children}
      </Typography>
    </Alert>
  );
}
