'use strict';

module.exports = function thresholdHasRawValue(threshold) {
  if (threshold.target?.metric) {
    return false;
  }

  if (threshold.preset) {
    return false;
  }

  const v = threshold.value;

  return v !== undefined && v !== null && v !== '' && (!Array.isArray(v) || v.length > 0);
};
