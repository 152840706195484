const ElasticQueryMatchCriterion = require('./ElasticQueryMatchCriterion.cjs');

module.exports = class ElasticQueryRangeCriterion extends ElasticQueryMatchCriterion {

  constructor(value, operator) {
    super(value);

    this.operator = operator;
  }

  toString() {
    return `${this.operator}${super.toString()}`;
  }

};
