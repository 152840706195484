'use strict';

/**
 * Converts a string so that it can be used as a slug.
 * A slug is a URL fragment so the output string must be URL safe. This implementation simply replaces all characters
 * that are not ASCII alphanumeric characters by dashes, which are URL safe. The resulting string is made lowercase.
 *
 * @param string {string} The input string
 *
 * @return {string} The input string, converted so that it's suitable to be used as a slug
 */
module.exports = function slugify(string) {
  return string
    .normalize('NFD') // Decomposes diacritics
    .replace(/\p{Diacritic}/gu, '') // Drops diacritics
    .replace(/[^A-Za-z0-9]/g, '-') // Drops non-ASCII characters
    .toLowerCase();
};
