import qs from 'query-string';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

const USE_QUERY_PARAMS_OPTIONS = {
  enableBatching: true,
  objectToSearchString: qs.stringify,
  searchStringToObject: qs.parse
};

export default function UseQueryParamsProvider({ children }) {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter} options={USE_QUERY_PARAMS_OPTIONS}>
      {children}
    </QueryParamProvider>
  );
}
