import technoOf from '../../helpers/technoOf.js';
import typeOf from '../../helpers/typeOf.js';
import uuidOf from '../../helpers/uuidOf.js';

const SEPARATOR = '~',
      TYPE_SEPARATOR = SEPARATOR + SEPARATOR,
      TECHNO_SEPARATOR = SEPARATOR + SEPARATOR + SEPARATOR;

/**
 * Serializes a `scope` array to a string.
 * The logic behind this function is to compress the data because sending a serialized JSON array takes up too much space,
 * and we hit URL length limits frequently because we pass the scope as a query param to the backend.
 * This function generates a string in the form:
 *
 *   techno1~~type1~uuid1~uuid2~...~~type2~uuid1~...~~~techno2~~...
 *
 * @param scope {object[]} The scope to serialize
 *
 * @returns {string} The serialized scope, suitable to be passed as URL query param
 */
export default function scopeSerializer(scope) {
  function groupByTechnoAndType(acc, item) {
    const techno = technoOf(item),
          type = typeOf(item);

    if (!(techno in acc)) {
      acc[techno] = {};
    }

    if (!(type in acc[techno])) {
      acc[techno][type] = [];
    }

    acc[techno][type].push(uuidOf(item));

    return acc;
  }

  function typeToString([type, uuids]) {
    return type + SEPARATOR + uuids.join(SEPARATOR);
  }

  function technoToString([techno, types]) {
    return techno + TYPE_SEPARATOR + Object.entries(types).map(typeToString).join(TYPE_SEPARATOR);
  }

  return Object.entries(scope.reduce(groupByTechnoAndType, {})).map(technoToString).join(TECHNO_SEPARATOR);
}
