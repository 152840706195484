import { QueryCache } from '@tanstack/react-query';

export default class ConfigurableQueryCache extends QueryCache {

  constructor() {
    super({
      onError: err => this.onError(err)
    });

    this.onErrorListeners = new Set();
  }

  addOnErrorListener(listener) {
    this.onErrorListeners.add(listener);
  }

  removeOnErrorListener(listener) {
    this.onErrorListeners.delete(listener);
  }

  /**
   * The main onError callback.
   * Will call all registered listeners with the passed-in Error.
   *
   * @param err {Error} The error that occurred
   */
  onError(err) {
    this.onErrorListeners.forEach(listener => listener(err));
  }

}
